// urls should use dashes "-" NOT underscores "_"!

export default [
  {
    name: 'home',
    path: '/',
    redirect: async (store, state) => {
      return {
        ...state,
        name: 'login',
        params: {},
      };
    },
  },
  {
    name: 'register',
    path: '/auth/register/?:invite&:code&:firstName&:lastName&:email&:utm_campaign&:utm_source&:utm_medium&:utm_content',
    component: () => import('./register'),
  },
  {
    name: 'external-login',
    path: '/auth/login/external/?:token',
    component: () => import('./external-login'),
  },
  {
    name: 'login',
    path: '/auth/login/?:token&:next&:invite&:message&:code&:email',
    component: () => import('./login'),
  },
  {
    name: 'external-redirect',
    path: '/auth/idp/?:email',
  },
  {
    name: 'external-signup-redirect',
    path: '/auth/idp/signup',
  },
  {
    name: 'flow',
    path: '/auth/flow/:flowId/',
    redirect: (store, state) => {
      return {
        ...state,
        name: 'flow.page',
        params: {
          ...state.params,
          path: 'root',
        },
      };
    },
    children: [
      {
        name: 'page',
        path: '*path',
        component: () => import('src/components/flows/flow'),
      },
    ],
  },
  {
    name: 'alert',
    path: '/auth/alert/?:message',
    component: () => import('./alert'),
  },
  {
    name: 'zipform',
    path: '/auth/zipform/?:firstName&:lastName&:email',
    redirect: async (store, state) => {
      return {
        ...state,
        name: 'login',
        params: {},
      };
    },
  },
  {
    name: 'sso',
    path: '/auth/sso_select/:id/',
    component: () => import('./sso'),
  },
  {
    name: 'initiateSso',
    path: '/auth/sso_initiate?:redirect&:mls',
    component: () => import('./initiate-sso'),
  },
  {
    name: 'errorpage',
    path: '/auth/error/?:key',
    component: () => import('./errorpage'),
  },
  {
    name: 'googleCallback',
    path: '/auth/google-callback/?:code&:state',
    redirect: async (store, state) => {
      const { params } = state;
      const res =
        await store.integrations.google.handleOauthCallbackAndReturnRedirect(
          params
        );
      if (res) {
        window.location.replace(res);

        // use never resolving promise to ensure that we don't start loading the page before
        // we have redirected
        return new Promise(() => null);
      }
      return {
        ...state,
        name: 'login',
      };
    },
  },
  {
    name: 'outlookCallback',
    path: '/auth/outlook-callback/?:code&:state',
    redirect: async (store, state) => {
      const { params } = state;
      const res =
        await store.integrations.outlook.handleOauthCallbackAndReturnRedirect(
          params
        );
      if (res) {
        window.location.replace(res);
        // use never resolving promise to ensure that
        // we don't start loading the page before
        // we have redirected
        return new Promise(() => null);
      }
      return {
        ...state,
        name: 'login',
      };
    },
  },
  {
    name: 'outlookError',
    path: '/auth/outlook-error/',
    component: () => import('./outlook-error'),
  },
  {
    name: 'resetPassword',
    path: '/auth/reset-password/',
    children: [
      {
        name: 'resetToken',
        path: ':token/',
        component: () => import('./reset-password/reset-token'),
      },
    ],
  },
  {
    name: 'noaccess',
    path: '/auth/orgs/noaccess',
    component: () => import('./noaccess'),
  },
  // Deprecated in favor of packagePublic
  {
    name: 'packageDownload',
    path: '/auth/package/download/:sideUuid/?action',
    redirect: (store, state) => {
      return {
        ...state,
        name: 'packageLanding',
        params: {
          actionUuid: state.params.action,
        },
      };
    },
  },
  {
    name: 'packageLanding',
    path: '/auth/package/:actionUuid',
    component: () => import('./package-download'),
  },
  {
    name: 'laorBlock',
    path: '/auth/laorblock/',
    component: () => import('./laor-block'),
  },
  {
    name: 'compassEmailBlock',
    path: '/auth/compass-email-block/',
    component: () => import('./compass-email-block'),
  },
];
