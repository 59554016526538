import '../polyfills';
import 'src/public-path';

import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';

import Root from './root';
import init from '../init';

init();

ReactDOM.render(<Root />, document.getElementById('root'));
