import AccountStore from './account-store';

import FeaturesStore from './features-store';
import IntegrationStore from './integration-store';
import LayoutStore from './layout-store';
import PDFAnnotationsStore from './pdf-annotations-store';
import PackagesStore from './packages-store';
import RouterStore from './router-store';
import TransactionStore from './transaction-store';
import UIStore from './ui-store';
import publicApi from 'src/api';
import routes from '../entries/auth/routes';
import { action, makeObservable } from 'mobx';

export class Authstore {
  appName = 'auth';

  constructor({
    Router,
    Transactions,
    Ui,
    Account,
    Layouts,
    Features,
    Integrations,
    PDFAnnotations,
    Packages,
    api,
  }) {
    makeObservable(this);

    this.api = api;
    this.ui = Ui && new Ui(this);
    this.router = Router && new Router(this, routes);
    this.transactions = Transactions && new Transactions(this);
    this.account = Account && new Account(this);
    this.features = Features && new Features(this);
    this.layouts = Layouts && new Layouts(this);
    this.integrations = Integrations && new Integrations(this);
    this.annotations = PDFAnnotations && new PDFAnnotations(this);
    this.packages = Packages && new Packages(this);
    this.initialized = false;
  }

  @action
  initialize(options) {
    if (this.initialized) {
      return;
    }

    this.options = options;
    this.env = this.options.env;
    this.debug = this.options.debug;
    this.account.initialize(options);
    this.transactions.initialize();
    this.ui.initialize();
    this.features.initialize();
    this.initialized = true;
  }

  startSpinning() {
    // deprecated
    this.ui.startSpinning();
  }

  stopSpinning() {
    // deprecated
    this.ui.stopSpinning();
  }

  @action
  forceStopSpinning() {
    // deprecated
    this.ui.forceStopSpinning();
  }

  handleError() {
    alert('Oops! Something went wrong.'); // eslint-disable-line
  }

  handleNotFoundError() {
    this.handleFatalError("Couldn't find what you were looking for.");
  }

  handleFatalError(message) {
    if (message) {
      this.ui.toast({
        message,
        type: 'error',
      });
    } else {
      this.ui.wentWrong();
    }
    this.router.navigate('home');
  }

  logOut() {
    this.account.logOut();
  }
}

export const subStores = {
  Router: RouterStore,
  Transactions: TransactionStore,
  Ui: UIStore,
  Account: AccountStore,
  Layouts: LayoutStore,
  Features: FeaturesStore,
  Integrations: IntegrationStore,
  PDFAnnotations: PDFAnnotationsStore,
  Packages: PackagesStore,
};

export default new Authstore({
  ...subStores,
  api: publicApi,
});
