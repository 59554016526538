

import React, { Component } from 'react';

import disclosureImg from 'src/images/services/disclosure_forms.svg';
import { Button } from 'antd';
import { observer } from 'mobx-react';

const clsPrefix = 'app-demo';

@observer
export default class StartDemo extends Component {
  startDemo = () => {
    window.location = '/transactions/seed_demo_account/';
  };

  render() {
    return (
      <div
        className={`${clsPrefix} round-box margin-bottom centered app-auth-box`}
      >
        <div className="align-center margin-bottom">
          <img
            className={`${clsPrefix}__img`}
            alt=""
            src={disclosureImg}
            onClick={this.startDemo}
          />
          <h3 className={`${clsPrefix}__title`}>
            A Safer, Faster Way to Prepare Real Estate Documents
          </h3>
        </div>
        <Button
          type="primary"
          size="large"
          className="app-register-form__register-btn width-100"
          onClick={this.startDemo}
        >
          Start Demo
        </Button>
      </div>
    );
  }
}
