/*
https://webpack.js.org/guides/public-path/:

"Be aware that if you use ES6 module imports in your entry file
the __webpack_public_path__ assignment will be done after the imports.
In such cases, you'll have to move the public path assignment to its
own dedicated module and then import it on top of your entry.js:"

hence this file which may be imported rather than putting the below line
at the top of your entry file.
*/

__webpack_public_path__ = window.WEBPACK_PUBLIC_PATH; // eslint-disable-line
