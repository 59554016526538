


import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { hot } from 'react-hot-loader';
import AppProvider from 'components/app-provider';
import Lazy from 'components/common/lazy';
import Match from 'components/common/match';
import withBrowserCheck from 'src/components/common/unsupported-browser';
import AppChrome from 'src/components/theme/app-chrome';
import { configureRouter } from 'src/router';
import authStore from 'src/stores/auth-store';
import { initGlobals } from '..';
import StartDemo from './components/start-demo';
import routes from './routes';

initGlobals();
const router = configureRouter(authStore, routes, {
  defaultRoute: 'login',
});
router.start();

authStore.initialize(window.Glide);

const App = inject('features')(
  observer(({ features }) => {
    const rootHref = features?.ucFeAuthVariation(
      'uc_fe_auth_login_path.tm_cn_ld.20221009'
    )
      ? '/hydra/auth/login'
      : undefined;
    return (
      <AppChrome className="page-auth-app" rootHref={rootHref}>
        {window.Glide.env === 'demo' ? (
          <div className="padded-top flex-long">
            <StartDemo />
          </div>
        ) : (
          <div className="padded-top flex-long">
            <Match
              routeName="external-login"
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./external-login')}
                />
              )}
            />
            <Match
              routeName="login"
              render={(route) => (
                <Lazy route={route} component={() => import('./login')} />
              )}
            />
            <Match
              routeName="register"
              render={(route) => (
                <Lazy route={route} component={() => import('./register')} />
              )}
            />
            <Match
              routeName="external-redirect"
              ignoreParams
              render={(route) => (
                <Lazy route={route} component={() => import('./external')} />
              )}
            />
            <Match
              routeName="external-signup-redirect"
              ignoreParams
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./external/back-to-sign-up')}
                />
              )}
            />
            <Match
              routeName="resetPassword"
              activeStrict
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./reset-password')}
                />
              )}
            />
            <Match
              routeName="flow.page"
              ignoreParams
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('components/flows/flow')}
                />
              )}
            />
            <Match
              routeName="resetPassword.resetToken"
              ignoreParams
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./reset-password/reset-token')}
                />
              )}
            />
            <Match
              routeName="alert"
              render={(route) => (
                <Lazy route={route} component={() => import('./alert')} />
              )}
            />
            <Match
              routeName="zipform"
              ignoreParams
              render={(route) => (
                <Lazy route={route} component={() => import('./zipform')} />
              )}
            />
            <Match
              routeName="sso"
              ignoreParams
              render={(route) => (
                <Lazy route={route} component={() => import('./sso')} />
              )}
            />
            <Match
              routeName="errorpage"
              ignoreParams
              render={(route) => (
                <Lazy route={route} component={() => import('./errorpage')} />
              )}
            />
            <Match
              routeName="outlookError"
              ignoreParams
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./outlook-error')}
                />
              )}
            />
            <Match
              routeName="noaccess"
              ignoreParams
              render={(route) => (
                <Lazy route={route} component={() => import('./noaccess')} />
              )}
            />
            <Match
              routeName="initiateSso"
              ignoreParams
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./initiate-sso')}
                />
              )}
            />
            <Match
              routeName="packageLanding"
              ignoreParams
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./package-download')}
                />
              )}
            />
            <Match
              routeName="laorBlock"
              ignoreParams
              render={(route) => (
                <Lazy route={route} component={() => import('./laor-block')} />
              )}
            />
            <Match
              routeName="compassEmailBlock"
              ignoreParams
              render={(route) => (
                <Lazy
                  route={route}
                  component={() => import('./compass-email-block')}
                />
              )}
            />
          </div>
        )}
      </AppChrome>
    );
  })
);

@withBrowserCheck()
@observer
class Root extends Component {
  render() {
    return (
      authStore.router.hasRoute && (
        <AppProvider store={authStore} router={router}>
          <App />
        </AppProvider>
      )
    );
  }
}

export default hot(module)(Root);
